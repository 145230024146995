import Vue from 'vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import App from './App.vue';

Vue.config.productionTip = false;

// Languages supported by this SPA
const languages = ['en', 'de'];
// Languages that will redirect to the arabic SPA instead of the english
const arLanguages = ['ar'];
// Languages that have an rtl writing direction
// const rtlLanguages = ['ar']

router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  if (!to.params.lang || !languages.includes(to.params.lang)) {
    const param = 'en';

    i18n.locale = param;
    store.commit('SET_LOCALE', param);
    next({ name: 'start-wbt', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = arLanguages.includes(lang);

    i18n.locale = lang;
    store.commit('SET_LOCALE', lang);

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created (): void {
    this.$store.commit('SET_SYSTEM_MODE', 'wbt');
  },
}).$mount('#app');


import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CommonIcon extends Vue {
  @Prop({ default: 'regular' })
  iconStyle!: 'regular' | 'solid' | 'light' | 'duotone' | 'brand' | string;

  @Prop(String)
  iconName!: string | null;

  @Prop(Boolean)
  marginRight!: boolean;

  @Prop(Boolean)
  loadingIcon!: boolean;

  get iconClass (): Record<string, boolean> {
    return {
      'jwl-icon--margin-right': this.marginRight,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get icon () {
    let { iconName, iconStyle } = this;
    if (this.loadingIcon) {
      iconName = 'spinner-third';
      iconStyle = 'duotone';
    }
    return () => import(`vue-svg-loader!./../assets/fa-icons/${iconStyle}/${iconName}.svg`);
  }
}

import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import routerView from 'common-modules/src/components/CommonRouterView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang',
    name: 'lang',
    component: routerView,
    redirect: (to: Route) => ({ name: 'start-wbt', lang: to.params.lang }),
    children: [
      {
        path: 'start/:wbtId?/:jwtToken?',
        name: 'start-wbt',
        component: () => import('@/views/WBT_View.vue'),
      },
    ],
  },
];

export default new VueRouter({
  mode: 'history',
  routes,
});

import {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import User, { UserData } from './User';
import Api, { SECUTIX_URL } from './Api';
import Concentrations from './Concentrations';
import { ErrorReport } from './interface/ErrorReport';
import { ConcentrationsData } from './interface/RunConcentration';

export const api = new Api();

export interface RejectResponse {
  trans: boolean,
  message: string,
  error: AxiosError,
}

export function handleErrorResponse (e: AxiosError): RejectResponse {
  const rejectReason = {
    trans: true,
    message: 'network.error.configurationError',
    error: e,
  };

  if (e.response) {
    rejectReason.message = e.response.data;
    rejectReason.trans = false;
  } else if (e.request) {
    rejectReason.message = 'network.error.serverError';
  }

  return rejectReason;
}

export interface ModuleState {
  currentUser: User | null;
  menuNodes: [];
  hasUserData: boolean;
  baseConcentrations: Concentrations | null;
  mode: string;
  errors: ErrorReport[];
}

const moduleState: ModuleState = {
  currentUser: null,
  menuNodes: [],
  hasUserData: false,
  baseConcentrations: null,
  mode: 'lms',
  errors: [],
};

const getters: GetterTree<ModuleState, ModuleState> = {
  currentUser (state: ModuleState) {
    return state.currentUser;
  },
  menuNodes (state: ModuleState) {
    return state.menuNodes;
  },
  hasUserData (state: ModuleState) {
    return state.hasUserData;
  },
  baseConcentrations (state: ModuleState) {
    return state.baseConcentrations;
  },
  systemMode (state: ModuleState) {
    return state.mode;
  },
};

export type ModuleMutations<S = ModuleState> = {
  SET_SYSTEM_MODE(state: S, payload: string): void;
  SET_CURRENT_USER(state: S, payload: UserData | null): void;
  SET_MENU(state: S, payload: []): void;
  SET_BASE_CONCENTRATIONS(state: S, payload: ConcentrationsData): void;
}

const mutations: MutationTree<ModuleState> & ModuleMutations = {
  SET_SYSTEM_MODE (state, payload: string) {
    state.mode = payload;
  },
  SET_CURRENT_USER (state, payload) {
    if (payload) {
      state.currentUser = payload;
      state.hasUserData = true;
    } else {
      state.currentUser = null;
      state.hasUserData = false;
    }
  },
  SET_MENU (state, payload) {
    state.menuNodes = payload;
  },
  SET_BASE_CONCENTRATIONS (state, payload) {
    state.baseConcentrations = new Concentrations(payload);
  },
};

type AugmentedActionContext = {
  commit<K extends keyof ModuleMutations>(
    key: K,
    payload: Parameters<ModuleMutations[K]>[1]
  ): ReturnType<ModuleMutations[K]>;
} & Omit<ActionContext<ModuleState, ModuleState>, 'commit'>;

const actions: ActionTree<ModuleState, ModuleState> = {
  getPublicData (store: AugmentedActionContext, url: string) {
    return new Promise((resolve, reject) => {
      api.publicAxios.get(url)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  getBaseData ({ commit, state }: AugmentedActionContext) {
    const forSis = state.mode === 'sis';
    const storeMode = forSis ? 'sis' : 'lms';
    return new Promise((resolve, reject) => {
      api.axios.get(`/api/general/current-user/${storeMode}`)
        .then((response: AxiosResponse<any>) => {
          commit('SET_CURRENT_USER', response.data.user);
          commit('SET_MENU', response.data.menu);
          if (response.data.concentrations) {
            commit('SET_BASE_CONCENTRATIONS', response.data.concentrations);
          }
          resolve(response.data.user.role);
        })
        .catch((exception: AxiosError) => reject(handleErrorResponse(exception)));
    });
  },
  logInUser (context: AugmentedActionContext) {
    return new Promise<void>((resolve, reject) => {
      api.publicAxios.get('/saml/login')
        .then(() => {
          resolve();
        })
        .catch((exception) => reject(handleErrorResponse(exception)));
    });
  },
  logOutUser ({ commit }: AugmentedActionContext) {
    api.logout();
    commit('SET_CURRENT_USER', null);
    window.location.href = `${SECUTIX_URL}/api/1/sso/saml/logout`;
  },
  getImpersonators (context: AugmentedActionContext, searchString: string) {
    return new Promise((resolve, reject) => {
      api.axios.post(`/api/general/impersonators/${searchString}`)
        .then((response: AxiosResponse<any>) => {
          resolve(response.data);
        })
        .catch((exception: AxiosError) => reject(exception));
    });
  },
};

const moduleStore = {
  state: moduleState,
  getters,
  mutations,
  actions,
};

export default moduleStore;


import { mapGetters } from 'vuex';
import CommonFooter from 'common-modules/src/components/CommonFooter.vue';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserInterface } from 'common-modules/src/store/interface/User';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    CommonFooter,
    CommonError,
    CommonIcon,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class App extends Vue {
  currentUser!: UserInterface;

  role: string | null = null;
  error = null;
  loaded = false;
  handleLoginDone = false;

  get showView (): boolean {
    return this.loaded;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (route: Route): void {
    if (route.name) {
      this.handleLogin();
    }
  }

  handleLogin (): void {
    if (this.handleLoginDone) {
      return;
    }
    if (this.$route.params.jwtToken) {
      this.$store.dispatch('handleWbtLogin', { jwtToken: this.$route.params.jwtToken })
        .then((data) => {
          const tokens = JSON.parse(data);
          this.$root.$store.commit('HANDLE_LOGIN', { token: tokens.token, refreshToken: tokens.refresh_token });

          // fetch user with lms jwttokens
          this.$store.dispatch('getBaseData')
            .then(() => {
              this.loaded = true;
            })
            .catch((error) => {
              this.error = error;
            });
        })
        .catch((e) => {
          this.error = e;
        });
    } else {
      // Skip if no wbt token is provided
      this.loaded = true;
    }
    this.handleLoginDone = true;
  }
}


import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CommonFooter extends Vue {
  get year (): number {
    const date = new Date();
    return date.getUTCFullYear();
  }

  get privacyPolicyUrl (): string {
    if (this.$root.$i18n.locale === 'de') {
      return 'https://www.boulezsaal.de/de/institution/datenschutzerklaerung';
    }

    return 'https://www.boulezsaal.de/en/institution/privacy-policy';
  }
}
